import React from "react";
import { connect } from "react-redux";
import GiftThanksView from "../components/GiftThanksView";
import withTranslation from "../hocs/withTranslation";
import { getStyles } from "../store/selectors";
import { resetShareGift } from "../store/user/actions";

const GiftThanksPage = (props) => <GiftThanksView {...props} />;

const mapStateToProps = (state, props) => {
  const {
    user,
    app: { keyboardOpen },
  } = state;

  return { user, keyboardOpen, appStyles: getStyles(state, props) };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    resetShareGift: () => dispatch(resetShareGift()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(GiftThanksPage));
